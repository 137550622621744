.usersListPage {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.usersListHeader {
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.headerBottomSection {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
}

.usersListFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
}

.usersListBody {
  flex-grow: 1;
  overflow: scroll;
}

.actionItemsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  img {
    cursor: pointer;
  }
}

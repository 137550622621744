.stripedTable {
  width: 100%;
  text-align: left;

  thead th {
    font-weight: normal;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  th, td {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  td {
    font-weight: normal;
    font-size: 14px;
  }
  tbody tr:nth-child(odd) {
    background-color: #F8FBFF;
  }
  th:first-child, td:first-child{
    padding-left: 40px;
  }
  th:last-child, td:last-child{
    padding-right: 40px;
  }
}

.buttonPrimary {
  border: none;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #006BD6;
  height: 50px;
  line-height: 50px;
  color: white;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;

  &.cancel {
    color: black;
    background-color: white;
    border: 1px solid #000000;
  }
}

.pageDropdownContainer {
  display: flex;
  flex-direction: row;
  height: 40px;
  min-width: 180px;

  span {
    line-height: 40px;
    padding-right: 15px;
    min-width: 120px;
  }

  :global(.MuiInputBase-root) {
    padding-right: 5px;
    padding-left: 10px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;

    &:global(.MuiInput-underline) {
      &:after, &:before, &:hover:not(:global(.Mui-disabled)):before {
        border-bottom: unset;
      }
    }

    :global(.MuiInputBase-input) {
      padding-right: 28px;

      &:focus {
        background-color: unset;
      }
    }

    :global(.MuiSvgIcon-root.MuiSelect-icon) {
      background-image: url('../../assets/arrow-chevron-down-icon.svg');
      background-repeat: no-repeat;
      background-position: center;
      padding-right: 10px;
      margin-top: -2px;

      path {
        opacity: 0;
      }
    }
  }
}

.employeesListPage {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.employeesListHeader {
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.headerTopSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .buttonContainer {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
}

.headerBottomSection {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
}

.employeesListFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
}

.employeesListBody {
  flex-grow: 1;
  overflow: scroll;
}

.roleSpan {
  > div {
    width: 128px;
    height: 32px;
    text-transform: capitalize;
    display: block;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .admin {
    background-color: #EAFFED;
    color: #2ABC3D;
  }
  .viewer {
    background-color: #EBEAFF;
    color: #6C63FF;
  }
  .member {
    background-color: #FFEFE9;
    color: #FC4C02;
  }
}

.actionItemsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  img {
    cursor: pointer;
  }
}
